<template>
  <div id="layout">
    <Header />
    <div class="layout-contain">
      <Sider />
      <Main />
    </div>
  </div>
</template>

<script>
import Header from './Header.vue'
import Main from './Main.vue'
import Sider from './Sider.vue'
export default {
  name: '',
  data() {
    return {}
  },
  components: {
    Header,
    Main,
    Sider,
  },
}
</script>

<style lang="less" scoped>
#layout {
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: #eff0f4;
  padding-top: 56px;
  overflow: auto;
  .layout-contain {
    display: flex;
  }
}
</style>
