function flat(arr) {
  return arr.reduce((pre, current) => {
    return pre.concat(Array.isArray(current) ? flat(current) : current)
  }, [])
}
// handler 函数必须返回一个对象
export function route_permis_manage(routes, handler) {
  const flatRoutes = flat(routes) // 转化为一维数组
  //   对传入的路由名称进行权限判断，router文件的meta对象中，verify属性为：Array 指定验证权限的字段
  const newRoute = flatRoutes.filter((route) => {
    //对有 meta对象并且有verify属性的路由对象进行验证
    if (route.meta && route.meta.verify) {
      // verify 必须为一个数组
      if (Array.isArray(route.meta.verify)) {
        if (handler(route.meta.verify)) {
          return route
        }
      } else {
        return new Error({ message: 'verify 必须为一个数组' })
      }
    } else {
      return route
    }
  })
  return newRoute
}
