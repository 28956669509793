<template>
  <div id="sider">
    <div class="_msfe_nav">
      <div class="__msfe__side-nav side-nav v2">
        <div class="nav-item-group full">
          <div
            class="nav-item"
            :class="{ active: component_data.location === '/home' }"
          >
            <img :src="require('@/assets/home-1.png')" width="16" height="16" />
            <router-link to="/home">
              <span class="nav-item-text" style="margin-left: 6px">Home</span>
            </router-link>
          </div>
        </div>
        <template
          v-for="(route, index) in component_data.routes"
          :key="route.name"
        >
          <div class="nav-item-group" v-if="route.meta && !route.meta.hidden">
            <div class="nav-item-group-title" @click="toggleCollapase(index)">
              <img :src="route.meta.icon" width="16" height="16" />
              <span>{{ route.meta.title }}</span>
              <!-- <div
                class="pdd-left-nav-arrow-expanded"
                :class="{ collapase: collapase.indexOf(index) > -1 }"
              ></div> -->
            </div>

            <ul
              class="nav-item-group-content"
              :style="{ height: childHeight(index, route.children) + 'px' }"
            >
              <template v-for="child in route.children" :key="child.path">
                <div
                  class="nav-item"
                  :class="{
                    active:
                      component_data.location === route.path + '/' + child.path,
                  }"
                  v-if="child.meta && !child.meta.hidden"
                >
                  <router-link :to="route.path + '/' + child.path">
                    <span class="nav-item-text">{{ child.meta.title }}</span>
                  </router-link>
                  <span class="nav-item-action"> </span>
                </div>
              </template>
            </ul>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { routes } from '../../router'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { onBeforeMount, reactive, computed } from 'vue'
import { route_permis_manage } from '@/utils/route_permis_process'

export default {
  setup() {
    const $store = useStore()
    const $route = useRouter()
    const component_data = reactive({
      routes: computed(() => {
        return route_permis_manage(routes, (verify) => {
          if ($store.state.userInfo && verify.length) {
            return !$store.state.userInfo[verify[0]]
          }
          return true
        })
      }),
      collapase: [],
      location: computed(() => {
        return $route.currentRoute.value.fullPath
      }),
    })
    onBeforeMount(() => {
      if ($store.state.userInfo && !$store.state.userInfo.role) {
        component_data.routes.forEach((e) => {
          if (e.name == 'accounts') {
            e.meta.hidden = false
          }
        })
      } else {
        component_data.routes.forEach((e) => {
          if (e.name == 'accounts') {
            e.meta.hidden = true
          }
        })
      }
    })
    function childHeight(index, routes) {
      const data = routes.filter((route) => route.meta && !route.meta.hidden)
      return component_data.collapase.indexOf(index) < 0
        ? Math.ceil(data.length) * 38 + 3
        : 0
    }
    function toggleCollapase(index) {
      let data = JSON.parse(JSON.stringify(component_data.collapase))
      let i = data.indexOf(index)
      i > -1 ? data.splice(i, 1) : data.push(index)
      component_data.collapase = data
    }
    return {
      component_data,
      childHeight,
      toggleCollapase,
    }
  },
}
</script>

<style lang="less" scoped>
@import '../../assets/colors.less';
#sider {
  width: 212px;
  height: calc(100vh - 56px);
  background-color: #fff;
  overflow: hidden auto;
  position: fixed;
  z-index: 999;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: @base-border-radius;
    border: 0 solid transparent;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
    }
  }
  ._msfe_nav {
    width: 212px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 6px;
    box-sizing: border-box;
    background: #fff;
    transition: color 0.3s ease;
    color: transparent;
  }
  .__msfe__side-nav.v2 {
    position: relative;
    width: 212px;
    padding: 0 20px;
    font-size: 14px;
    background-color: #fff;
    user-select: none;
    .nav-item-group {
      padding-top: 6px;
      &::after {
        content: ' ';
        background-color: #f0f0f0;
        height: 1px;
        display: block;
        margin-top: 6px;
      }
      .nav-item-group-title {
        position: relative;
        color: rgba(0, 0, 0, 0.8);
        cursor: pointer;
        height: 33px;
        line-height: 33px;
        img {
          position: absolute;
          top: 9px;
        }
        span {
          font-size: 14px;
          line-height: 34px;
          margin-left: 22px;
          font-weight: 600;
        }
        .pdd-left-nav-arrow-expanded {
          border-color: #b9bfbd;
          border-style: solid;
          border-width: 0 0 1px 1px;
          display: inline-block;
          width: 8px;
          height: 8px;
          right: -18px;
          top: 12px;
          position: absolute;
          transition: transform 0.2s ease-out 0s;
          transform: rotate(-225deg);
          margin-right: 20px;
        }
        .pdd-left-nav-arrow-expanded.collapase {
          transform: rotate(-45deg);
        }
      }
      ul {
        list-style: none;
        margin: 0;
        padding-bottom: 3px;
        padding-left: 22px;
        overflow: hidden;
        transition: all 0.2s;
      }
      .nav-item {
        position: relative;
        line-height: 38px;
        color: #666;
        cursor: pointer;
        list-style: none;
        display: flex;
        align-items: center;
        padding: 0;
        &:hover,
        &.active {
          a {
            .nav-item-text {
              color: @primary-color;
            }
          }
        }
        a {
          font-size: 14px;
          text-decoration: none !important;
          color: unset;
          height: 100%;
          display: inline-block;
          .nav-item-text {
            display: inline-block;
            line-height: 1;
            position: relative;
            font-size: 13px;
          }
        }
        .nav-item-action {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 12px;
        }
      }
    }
    .full {
      .nav-item {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        font-size: 14px;
        &.active {
          color: @primary-color;
        }
      }
    }
  }
}
</style>
