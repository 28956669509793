<template>
  <div id="cc-container">
    <main class="cc-main">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
#cc-container {
  // margin: 0 16px;
  flex: 1 1;
  width: 0;
  margin-left: 212px;
  min-height: calc(100vh - 56px);
  .cc-main {
    min-width: 1064px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 64px;
    height: 100%;
    overflow: hidden;
  }
}
</style>
